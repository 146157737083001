<template>
  <CCard>
    <CCardHeader>
      Add Device
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="6">
          <CRow>
            <CCol sm="12">
              <CInput
                placeholder="Device Name"
                class="mb-4"
                prepend="Name"
                type="text"
                :value.sync="deviceName"
              />
            </CCol>
            <CCol sm="12">
              <CSelect
                placeholder="Type"
                :options="deviceTypeItem"
                prepend="Type"
                :value.sync="deviceType"
              />
            </CCol>
            <CCol sm="12">
              <CInput
                placeholder="Like router.network.com,It Can be empty."
                class="mb-4"
                prepend="Hostname"
                type="text"
                :value.sync="hostname"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CInput
                placeholder="Like 1.1.1.1"
                class="mb-4"
                prepend="Main IP"
                type="text"
                :value.sync="mainIP"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CButton
                color="primary"
                square
                @click="addDevice"
              >
                Submit
              </CButton>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCardBody>
    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Request Message"
      color="primary"
      :show.sync="requestMsgModal"
    >
      {{ requestMsg }}
      <template #footer>
        <CButton @click="requestMsgModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
export default {
  name: "AddDevice",
  data() {

    return {
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      requestMsgModal: false,
      requestMsg: null,
      routerListItem: [],
      deviceTypeItem: [],
      deviceName: null,
      deviceType: null,
      hostname: null,
      mainIP: null,
      routerId: null
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/cmdb/device/type", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.deviceTypeItem = response.data.type.map(item => {
              return {
                label: item.type_name,
                value: item.id
              }
            });
          }
        });
    },
    addDevice: function() {
      this.$axios.post("user/cmdb/device/add", {
        name: this.deviceName,
        hostname: this.hostname,
        type: this.deviceType,
        main_ip: this.mainIP,
        router_id: this.routerId,

      })
        .then((response) => {
          this.requestMsg = response.data.msg;
          this.requestMsgModal = true;
        });
    }
  }
};
</script>